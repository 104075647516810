import React from 'react';
import './Error.css'; // Create this CSS file to style the page according to your existing design

const ErrorPage = () => {
    return (
        <div className="error-container">
            <div className="error-card">
            <img 
                    src="/denied_access.png" 
                    alt="Complieaze Logo" 
                    className="error-logo"
                />
            <div className="access-logo">
                <span style={{ color: "#3F9BA1" }}>Compli</span>
                <span style={{ color: "#000" }}>eaze</span>
              </div>
                <h1 className="error-title">Access Denied</h1>
                <p className="error-message">
                    You have not been invited to join Complieaze. Please contact your administrator for access.
                </p>
                <button className="contact-button" onClick={() => window.location.href = '/'}>
                    Login
                </button>
            </div>
        </div>
    );
};

export default ErrorPage;
