import React, { useState, useEffect } from 'react';
import './Login.css';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignInPage = () => {
  const history = useHistory(); 
  
  const handleLogin = (e) => {
    e.preventDefault();
  };
  
  const handleMicrosoftLogin = () => {
    window.location.href = "/microsoft/login";
    // handlePostLoginCheck();
  };
  // const handlePostLoginCheck = async () => {
  //   try {
  //     const response = await fetch('/microsoft/check_invitation', { method: 'GET' });
  
  //     if (response.status === 403) {
  //       toast.error('You are not invited to access this application');
  //     } else {
  //       toast.success('Invitation check passed!');
  //     }
  //   } catch (error) {
  //     toast.error('An error occurred while checking invitation status.');
  //   }
  // };
  
  // useEffect(() => {
  //   handlePostLoginCheck();
  // }, []);
  
  return (
    <div className="login-container">
      <div className="left-container">
        <div className="left-container-content">
          <div className="complieaze-logo-login">
            <span style={{ color: "#3F9BA1" }}>Compli</span>
            <span style={{ color: "#000" }}>eaze</span>
          </div>
          <div className="title">Regulatory compliance assistant</div>
          <div className="footer-container">
            <a href="/PrivacyPolicy.pdf" target="_blank" rel="noopener noreferrer" className="footer-link">Privacy Policy</a>
            <span className="footer-separator"> | </span>
            <span className="footer-text">Product by</span>
            <img src="/dataease-logo.png" alt="Product Logo" className="footer-image" />
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="sign-up">
          <h2>Login</h2>
          <input className="email-input disabled-input" type="text" placeholder="Enter your email" disabled />
          <input className="password-input disabled-input" type="password" placeholder="Password" disabled />
          <div className="button-container">
            <div className="submit-container">
              <button className="submit-button disabled-input" onClick={handleLogin} disabled>
                Login
              </button>
            </div>
            <div className="google-login-container">
              <button className="google-login-button" onClick={handleMicrosoftLogin}>
                <img src="/microsoft.png" alt="Microsoft Icon" />
                Microsoft
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick pauseOnHover draggable />
    </div>
  );
};

export default SignInPage;
