import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Multiselectdropdown.css'; 

const MultiSelectDropdown = ({ optionsList, placeholder, onSelectionChange, selectedOptions: initialSelectedOptions }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions || []);
  const [dropdownSearchTerm, setDropdownSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); 
  const dropdownRef = useRef(null); 

  useEffect(() => {
    setSelectedOptions(initialSelectedOptions);
  }, [initialSelectedOptions]);


  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(optionsList);
    }
    setSelectAll(!selectAll);
    onSelectionChange(selectAll ? [] : optionsList); 
  };

  const Chip = ({ label, onRemove }) => (
    <div className="dropdown-chip">
      {label}
      <span className="remove-chip" onClick={onRemove}>
        &times;
      </span>
    </div>
  );

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
    onSelectionChange(selectedOptions.includes(option) ? selectedOptions.filter((item) => item !== option) : [...selectedOptions, option]);
  };

  const handleDropdownSearch = (e) => {
    setDropdownSearchTerm(e.target.value);
  };


  const filteredOptions = optionsList.filter((option) =>
    option.toLowerCase().includes(dropdownSearchTerm.toLowerCase())
  );

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="multi-select-dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
      <div className="dropdown-chips-container">
      {selectedOptions.length ? (
        selectedOptions.map((option, index) => (
          <span key={index} className="chip selected-chip">
            {option}
          </span>
        ))
      ) : (
        <span className="dropdown-placeholder">{placeholder}</span>
      )}
    </div>
        {/* <span className="dropdown-placeholder">{selectedOptions.length ? selectedOptions.join(', ') : placeholder}</span> */}
        <span className="arrow">&#9660;</span> 
      </div>

      {dropdownOpen && (
        <div className="dropdown-options">
          <label className="dropdown-item">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            Select All
          </label>

          {filteredOptions.map((option, index) => (
            <label className="dropdown-item" key={index}>
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  optionsList: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onSelectionChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array 
};

export default MultiSelectDropdown;
